<template >
    <v-container fluid>
        <v-layout wrap>
            <v-flex fluid>
                <v-card :loading="loading" outlined style="border: none;">
                    <v-card-title class="px-1 d-flex align-center justify-center">
                        <p class="font-weight-black" style="color: #306AA0;">
                            Prontuário do Paciente
                        </p>
                    </v-card-title>
                    <p v-show="files.length <= 0 && !loading" class="font-weight-black" style="color: #306AA0;" > Sem paciente ou Sem Conexão de Internet</p>
                    <v-expansion-panels focusable class="px-1">
                        <v-expansion-panel
                            v-for="(item, i) in files " 
                            :key="i"
                        >
                            <v-expansion-panel-header><strong>Paciente: {{ item.nome }} -> ID: {{item.id}}</strong></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                    <v-col>
                                        <v-row>
                                            <v-textarea class="mx-1 mt-4" rows="1" outlined dense :value="item.data_nascimento | formatData" readonly label="Data de Nascimento"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.rg" readonly label="RG"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.cpf" readonly label="CPF"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.cid.nome" readonly label="CID"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.cid.descricao" readonly label="Descrição do CID"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense  readonly label="Tipo Sanguine"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.alergia" readonly label="Alergia"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.operadora.nome" readonly label="Operadora"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.modalidade.nome" readonly label="Modalidade"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.endereco.logradouro" readonly label="Endereço: Logradouro"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.endereco.bairro" readonly label="Bairro"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.endereco.nr" readonly label="Nº"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.endereco.cidade" readonly label="Cidade"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.endereco.estado" readonly label="UF"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.endereco.estado" readonly label="CEP"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense readonly label="MAPA"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.responsavel" readonly label="Responsável"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.telefone" readonly label="Telefone"></v-textarea>
                                            <v-textarea class="mx-1 mt-1" rows="1" outlined dense :value="item.medico.username" readonly label="Médico Responsável"></v-textarea>
                                            <v-textarea v-if="item.geolocalizacao" class="mx-1 mt-1" rows="1" outlined dense :value="`LAT ${item.geolocalizacao.lat} - LNG ${item.geolocalizacao.lng}`" readonly label="Geolocalização"></v-textarea>
                                        </v-row>
                                    </v-col>
                                    <!-- Plano Terapêutico Vigente-->
                                    <v-col>
                                        <p class="font-weight-black" style="color: #306AA0;">
                                            Plano Terapêutico Vigênte
                                        </p>
                                        <v-card-text>
                                            <pre> {{ item.sessoes}} </pre>
                                            <v-data-table :headers="headers" :items="item.sessoes" class="elevation-1"></v-data-table>
                                        </v-card-text>
                                    </v-col>
                                    <!-- Equipe Multidisciplinar do paciente -->
                                    <v-col>
                                        <p class="font-weight-black" style="color: #306AA0;">
                                            Equipe Multidiscplinnar
                                        </p>
                                        <v-card-text>
                                            <pre> {{ item.sessoes}} </pre>
                                            <v-data-table :headers="headers" :items="item.sessoes" class="elevation-1"></v-data-table>
                                        </v-card-text>
                                    </v-col>
                                        <!-- Evoluções Realizadas-->
                                    <v-col>
                                        <p class="font-weight-black" style="color: #306AA0;">
                                            Evoluções
                                        </p>
                                        <v-card-text>
                                            <pre> {{ item.sessoes}} </pre>
                                            <v-data-table :headers="headers" :items="item.sessoes" class="elevation-1"></v-data-table>
                                        </v-card-text>
                                    </v-col>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import api from '../../../../http';

export default {
    name: "prontuario_paciente",
    created() {
        window.scroll(0,0);
    },
    data: () => ({
        dialog: false,
        dialogDelete: false,
        files: [],
        loading:false,

    }),
    computed:{
        sessoes(){
            var res = []
            return res
        },
    },
    methods:{
        get_paciente(){
            this.loading = true;
            api(`pacientes/listar/?colaborador=9`)
            .then(r=>{
                this.files = r.data
                console.log(r.data)
            })
            .catch(error=>{
                this.msg = error
            })
            .finally(()=>{
                this.loading = false
            });
        },
    },
    mounted(){
        this.get_paciente()
    }
}
</script>
